import styles from "./button.module.scss";

interface ChildrenProps {
  type?: string;
  variant?: string;
  children?: any;
  className?: string;
  onClick?: (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  [attr: string]: any;
}

const Button = ({
  type = "button",
  variant = "primary",
  children = "",
  className = "",
  onClick,
  ...attr
}: ChildrenProps) => {
  return (
    <>
      {type === "link" && (
        <a
          onClick={onClick}
          className={`${styles.button} ${styles[variant]} ${className}`}
          {...attr}
        >
          {" "}
          {children}
        </a>
      )}

      {type === "button" && (
        <button
          onClick={onClick}
          className={`${styles.button} ${styles[variant]} ${className}`}
          {...attr}
        >
          {children}
        </button>
      )}

      {type === "submit" && (
        <input
          type={type}
          value={children}
          className={`${styles.button} ${styles[variant]} ${className}`}
          {...attr}
        />
      )}
    </>
  );
};

export default Button;
