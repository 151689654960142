import React from "react";
import { updateCalendly } from "../../services/social";
import Button from "../../components/atoms/button";
import styles from "./socialCallback.module.scss";
const CalendlyEventList = () => {
  const calendlyEventData = JSON.parse(
    window.localStorage.getItem("calendlyEventData") ?? "{}"
  );

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const selectedEventIndex = parseInt(event.target.calendlyEventRadio.value);

    const selectedEvent =
      calendlyEventData?.data?.data?.calendlyEvents?.[selectedEventIndex];

    const data = {
      calendlyEventSchedulingUrl: selectedEvent?.scheduling_url,
      calendlyEventSlug: selectedEvent?.slug,
      calendlyUserSlug: calendlyEventData?.data?.data?.calendlyUserSlug,
    };

    const calendlyData = await updateCalendly(data);
    window.localStorage.setItem("calendlyData", JSON.stringify(calendlyData));
    if (calendlyData?.status === 200) {
      window.opener.location.reload();
      window.close();
    } else {
      alert(
        calendlyData?.data?.exception?.error?.message || "Something went wrong"
      );
    }
  };

  return (
    <div className={styles.eventListWrapper}>
      <h2>Calendly Events</h2>
      <form onSubmit={handleSubmit}>
        <p className='mb-3'>
          Please select the Calendly event you want to link with Evise:
        </p>
        {!!calendlyEventData?.data?.data?.calendlyEvents.length
          ? calendlyEventData?.data?.data?.calendlyEvents?.map(
              (event: any, index: number) => (
                <div key={index} className='radio-wrapper'>
                  <label className='custom-checkradio'>
                    <input
                      type='radio'
                      name='calendlyEventRadio'
                      value={index.toString()}
                    />
                    <span className='checkmark'></span>
                    {event?.name}
                  </label>
                </div>
              )
            )
          : "No hourly events found on your calendly account"}
        <Button variant='light' className='w-100' type='submit'>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default CalendlyEventList;
